* {
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
  text-decoration: none;
 font-family: 'Montserrat', sans-serif !important;
}

* {
  margin: 0;
  padding: 0;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
}

body {
  background-color: #000;
}

.main {
  max-width: 2000px;
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  align-content: center;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.content {
  position: absolute;
  max-width: 2000px;
  height: 100%;
  max-height: 1000px;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto !important;
  color: white;
}

.VideoHomeContainer {
  max-width: 2000px;
  margin: 0 auto;
}

.PrincipalLogoHome {
  width: 30% !important;
  max-width: 150px;
}

.overlay {
  position: absolute;
  width: 100%;
  max-height: 1125px;
  max-width: 2000px;
  top: 0;
  margin: 0 auto !important;
  height: calc(114.3%);
  background-color: rgba(0, 0, 0, .4);
}

.HomeTitle {
  font-size: 3rem;
}



/*------------------------ Components  ------------------------ */

/* Video Home Styles */

.LandingPageTitle {
  font-size: 2.5rem !important;
}

.LandingPageSubTitle {
  max-width: 900px;
  font-size: 1.3rem;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 50px;
}

.HighLightHome{
  color: black;
  font-weight: bolder !important;
}

.LandingPageBtn {
  margin-top: 10px;
  width: 120px;
  height: 30px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: #000;
  color: white;
  outline: none;
  cursor: pointer;
  transition: 0.9s;
  font-size: 0.8rem;
  padding: 10px;
}



.LandingPageBtn:hover {
  background-color: white;
  color: black;
}

.containerDownArrow {
  position: absolute;
  margin-top: 40% !important;
  color: white;


}



.DownArrowLandingPage {
  animation: up-to-down 2s ease-in forwards infinite alternate;

}



@keyframes up-to-down {

  100% {
    transform: translateY(100%);

  }
}


/* ------------------------ NavBar Landing Home ------------------------ */



.Navbar-logo {
  width: 150px;
}



/* ------------------------ Section Services ------------------------ */

.ServicesCardsContainer {
  display: flex;
  justify-content: center;
  max-width: 2200px;

  flex-wrap: wrap;

}


.SectionServicesContainer {
  background-color: #000;
  max-width: 2000px;
  margin: 0 auto;
  padding-top: 50px;
  padding-bottom: 100px;


}

.ServicesContainerTitle {
  color: white;
  max-width: 1000px;
  padding: 20px 6px 20px 6px;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 70%;
  margin: 0 auto;
  font-size: 1.5rem;
  text-align: center;
}

.ServicesCard {
  border: 1px solid #d7d7d7;
  color: #d5d5d5;
  max-width: 350px;
  min-width: 300px;
  max-height: 220px;
  margin-top: 15px;
  border-radius: 20px;
  margin-left: 5px;
  margin-right: 5px;
  padding: 20px;
}

.CasesStudyContainer {
  display: flex;
  width: 100%;
  margin: 0 auto;
  flex-direction: column;
  max-width: 2000px;

}

.CardSubTitle {
  font-size: 0.7rem;
}

.CardServicesTitle {
  font-size: 2rem;
  margin-top: 20px;
}

.CardServicesDescription {
  margin-top: 5px;
  font-size: 0.9rem;
}

.CardServicesbtn {
  color: white;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

.ArrowServicesCont {
  margin-left: 20px;
}


.RightArrowServices {
  margin-top: 5px;
}

.RightArrowServices {
  animation: left-to-right 2s ease-in forwards infinite alternate;

}


.ServicesCardSecondRow {
  background-color: black !important;
  color: #d5d5d5 !important;
}

.CSSecondRow {
  color: white !important;
}

@keyframes left-to-right {

  100% {
    transform: translateY(50%);

  }
}

.ServicesCardCenter {
  background-color: white;
  color: #000;
  border: 1px solid #fff;

}

.CardServicesbtncenter {
  color: #000;
}

/* ------------------------ Partner Google Banner ------------------------ */

.PartnerBGoogleContainer {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  max-width: 2000px;
  margin: 0 auto;
  flex-wrap: wrap;
  padding: 50px;
  justify-content: center;
}

.PartnerBGoogleInfoCont {
  display: flex;
  padding: 20px;
  max-width: 2000px;
  margin: 0 auto;
  justify-content: center;
}

.DescriptionBGoogle {
  padding: 20px;
  width: 50%;
}

.DescriptionPartner {
  margin-top: 40px;
  color: black;

}

.PartnerBtn {
  margin-top: 10px;
  width: 130px;
  height: 40px;
  border-radius: 20px;
  border: 1px solid black;
  background-color: #000;
  color: white;
  outline: none;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  transition: 0.9s;
}

.PhoneBtnGP{
  margin-right: 5px !important;
}


/* ------------------------ Call To action ------------------------ */

.CallToActionContainer {
  background-color: #fff;
  color: black;
  display: flex;
  padding: 100px;
  justify-content: space-evenly;
  align-items: center;
  max-width: 2000px;
  margin: 0 auto;
  flex-wrap: wrap;
}

.CallToActionInformation {
  width: 50%;
  max-width: 500px;
  padding: 20px;
}

.PrincipalCallToActionTitle {
  color: #000;
  margin-bottom: 30px;
  font-size: 1.5rem;

}

.PrincipalCallDesc {
  margin-top: 20px;

}

.PrincipalHowDesc {
  margin-top: 20px;
}

.HowBtn {
  max-width: 200px;
  margin-top: 20px;
  height: 45px;
  border-radius: 20px;
  border: none;
  color: #000;
  cursor: pointer;
  background-color: white;
  transition: 0.9s;
}

.HowBtn{
  display: none;
}

.CallActionimg {
  max-width: 500px;
}

.HowBtn:hover {
  background-color: black;
  color: white;
  border: 1px solid white;
}

.CABtn{
  display: flex;
  justify-content: center;
  align-content: center;
  width: 180px;
  align-items: center;
  max-width: 200px;
  margin-top: 20px;
  height: 45px;
  border-radius: 20px;
  border: none;
  color: white;
  cursor:auto;
  background-color: black;
  transition: 0.9s;
}


.PhoneCallToAction{
  width: 16px;
  margin-right: 20px;
}

/* ------------------------ Team Section ------------------------ */

.TeamSectionContainer {
  color: black;
  background-color: white;
  padding: 60px;
  max-width: 2000px;
  margin: 0 auto;
}

.BannerTeamTitle {
  max-width: 1400px;
  width: 75%;
  margin: 0 auto;
  font-size: 2rem;
  text-align: justify;
  font-weight: normal !important;
  padding-top: 50px;
}

.HighLightBlack {
  font-weight: bold !important;
}

.PrincipalTitleTeamSection {
  font-size: 2rem;
  padding-top: 30px;
  width: 20%;
  text-align: center;
  margin: 0 auto;
}

.ContainerAvatars {
  display: flex;
  margin: 0 auto;
  max-width: 1100px;
  justify-content: space-around;
  margin-top: 50px;
  flex-wrap: wrap;
}

.TeamSubtitle {
  width: 30%;
  font-size: 1.5rem;
  text-align: center;
  margin: 0 auto;
  padding-top: 10px;
}

.AvatarTeamImg {
  width: 100%;
  max-width: 200px;

}

.AvatarCard {
  min-height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.AvatarName {
  text-align: center;
}

/* ------------------------ How Section ------------------------ */

.HowSectionContainer {
  background-color: #000;
  color: white;
  max-width: 2000px;
  margin: 0 auto;
}

.HowImg {
  width: 35%;
  max-width: 600px;
}

.HowSectionContainerDescription {
  display: flex;
  padding: 100px;
  justify-content: space-evenly;
  margin: 0 auto;
  flex-wrap: wrap;
  max-width: 2000px;
}

.HowVideo {
  max-width: 500px;
}

.HowInfoContainer {
  max-width: 600px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  justify-content: center;
}

.PrincipalTitleHow {
  font-size: 1.5rem;
}

/* ------------------------ HowTwo Section ------------------------ */


/* ------------------------ About Us Section ------------------------ */

.AboutUsSectionContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.PrincipalTitleAboutUs {
  margin-bottom: 20px;
  display: flex;
  text-align: center !important;
  justify-content: center;
}

.AboutUsInfoContainer {
  color: white;
  width: 80%;
  max-width: 1700px;
  text-align: center;
  padding: 50px;
  font-size: 1.5rem;

}

.Type {
  margin-left: 5px;
}

.AboutUsDescription {

  font-size: 1.3rem;

}

.AboutUsImg {
  max-width: 800px;
  margin: 0 auto !important;
}


/*------------------------ Adwords Count Up  ------------------------ */

.CountUpContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  padding: 20px;
  max-width: 2000px;
  margin: 0 auto;

}

.CountUpCard {
  display: flex;
  flex-direction: column;
  font-size: 1.3rem;
  width: 130px;
  text-align: center;
}

.CountUpNumber {
  color: white;
  margin-top: 20px;
}

/*------------------------ Container BLockHowTwoContainer  ------------------------ */

.BLockHowTwoContainer {
  padding: 100px;
}


/*------------------------ Container BLock How Center Container  ------------------------ */

.BlockHowCenterContainer {
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px;
  max-width: 2000px;
  margin: 0 auto;
  align-items: center;
}

.BlockHowCenterTitleBold {
  font-size: 1.8rem;
  font-weight: bold;
  text-align: center;
}

.BlockHowCenterTitle {
  font-size: 1.8rem;
  font-weight: normal;
  text-align: center;
  margin: 0 auto;
}

.BlockHowCenterImage {
  max-width: 600px;
}

.CustomPlanContainer {
  width: 80vw;
  max-width: 2000px;
  max-height: 500px;
  border-radius: 20px;
  background-color: white;
  border: 1px solid #d7d7d7;
  color: #d5d5d5;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  margin-bottom: 60px;
  margin-top: 40px;
}

.CustomPlanInfoContainer {
  width: 60%;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.FreeQuoteBtn {
  max-width: 200px;
  margin-top: 20px;
  height: 40px;
  border-radius: 10px;
  outline: none;
  border: none;
  background-color: black;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.9s;
}

.PricingQuoteBtn{
  width: 16px;
  margin-right:20px ;
}

.CustomDescriptionPricing {
  color: black;
  margin-top: 20px;
}

.PricingSectionCustomTitle {
  color: black;
  font-size: 1.5rem;
}

/* ------------------------ Pricing Section ------------------------ */


.PricingContainerSection {
  display: flex;
  justify-content: space-evenly;
  padding: 20px;
  max-width: 2000px;
  flex-wrap: wrap;
  margin: 0 auto;
}

.PricingSectionTitleBold {
  color: white;
  font-weight: bold;
  font-size: 1.8rem;
  margin: 0 auto;
  max-width: 1000px;
  margin-top: 40px;
  margin-bottom: 40px;

}

.PlansTitle {
  color: white;
  margin: 0 auto;
  text-align: center;
  width: 100px;
  font-size: 1.8rem;
  padding-top: 20px;
  padding-bottom: 20px;
}

.PricingTitleNormal {
  font-weight: normal !important;
  font-size: 1.6rem;


}

.EServicesCardPricing {
  margin-top: 10px;
  display: flex;
}

.ServicesCardPricing {
  height: 100% !important;
  border: 1px solid #d7d7d7;
  color: #d5d5d5;
  min-width: 300px;
  max-height: 400px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  padding: 20px;
}

.PricingCheck {
  width: 16px;
  margin-right: 5px;
}

.CardPricingBtn {
  margin-top: 20px;
  color: black;
  border-radius: 10px;
  padding: 10px;
  max-width: 120px;
  text-align: flex-start;
  background-color: white;
  cursor: pointer;
  transition: 0.9s;
}

.CardPricingBtn:hover {
  background-color: black;
  color: white;
  border: 1px solid white;
}


.CustomQuoteImg {
  max-width: 300px;
}

/* ------------------------ Quote Now Section ------------------------ */

.QuoteNowSectionContainer {
  height: 100%;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 60px !important;
  border: 1px solid #d7d7d7;
  max-width: 1100px;
  flex-wrap: wrap;
  display: flex;
  margin: 0 auto;
}

.ContactUsTitle {
  color: white;
  max-width: 950px;
  margin: 0 auto;
  font-size: 2rem;
  padding: 20px;

}

.InfoQuoteTitle {
  color: white;

}

.TouchWithUsContainer {
  margin-top: 60px;
}

.QuoteNowFullContainer {
  max-width: 1100px;
  margin: 0 auto;
  justify-content: center;
  display: flex;
}


.QuoteNowInfoContainer {
  padding: 20px;
  margin-top: 40px !important;
  max-width: 400px;
}

.QuoteIcon {
  width: 16px;
  margin-right: 5px;
}

.QuoteNowDescriptionContact {
  color: white;
  display: flex;
  max-width: 300px;
  margin-top: 20px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.QuoteNowDescriptionText {
  color: white;
  margin-top: 10px;
  max-width: 400px;
}

.QuoteBtnSendContainer {
  width: 100%;
  max-width: 520px;
}

.QuoteSendBtn {
  height: 40px;
  width: 530px;
  background-color: black;
  color: white;
  border: 1px solid #d7d7d7;
  cursor: pointer;
  transition: 0.9s;
  border-top: none;
}

.QuoteSendBtn:hover {
  background-color: white;
  color: black;
}

::placeholder {
  font-size: 1rem;
  padding: 10px;
}

.QuoteFormPiramide {
  width: 80px;
  margin-bottom: 20px;
}

/* ------------------------ Form Quote Section ------------------------ */

.FormContainer {
  margin-top: 40px !important;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;

}

.InputContainer {
  display: flex;
  width: 100%;
  justify-content: center;

}

.InputContainerTwo {
  margin-top: 20px !important;
}

.TextTareaInput {
  border: 1px solid #d7d7d7;
  margin-top: 20px;
  width: 530px;
  height: 150px;
  background-color: black;
  outline: none;
  color: white;
  border-bottom: none;
  resize: none;


}


.QuoteInput {
  height: 45px;
  color: white;
  width: 100%;
  max-width: 250px;
  border: 1px solid #d7d7d7;
  background-color: black;
  outline: none;
  margin: 0px 10px 0px 10px;

}

.QuotePhoneInput {
  max-width: 430px;
  width: 100%;
}

.PreNumberQuote {
  width: 50px;
}

.QuoteEmailInput {
  max-width: 400px;
  width: 100%;
}

/* ------------------------ LeadsGenerated Section ------------------------ */

.LeadsGeneratedSection {
  background-color: white;
  max-width: 2000px;
  margin: 0 auto;
  padding: 50px;
}

.LeadGeneratedTitle {
  width: 85%;
  text-align: center;
  font-size: 1.5rem;
  padding: 30px;
  font-weight: bold;
  margin: 0 auto;
}

.SpanInTitle {
  font-weight: normal;
}

.LogoLetters {
  width: 200px;
  padding: 15px;
}

.LeadsGeneratedTopCont {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.AmazonLogo {
  margin-top: 20px;
}

.CIALogo {
  width: 170px;
  padding: 20px;
}

.NasaLogo {
  width: 180px;
}

.Phizer {
  width: 200px;
  padding: 30px;
}

/* ------------------------ Footer Section ------------------------ */

.FooterSectionContainer {
  background-color: #000;
  padding: 60px;
}

.FooterColumnsContainer {
  display: flex;
  justify-content: center;
}


.LogoFooter {
  font-size: 1.4rem;
  color: white;
  cursor: pointer;

}

.FooterColumn {
  color: white;
  margin-left: 70px;
  margin-right: 70px;
}

.LinkFooter {
  margin-top: 10px;
}

.TitleFooter {
  font-size: 1rem;
  color: #d5d5d5;
}

.LinkFooter {
  font-size: 1rem;
  color: #d5d5d5;
}

.RightReservedFooter {
  color: white;
}

.RightReservedFooter {
  text-align: center;
  padding: 5px;
}


.FooterLogo {
  width: 100px;
}



/*  Start Media  */



/*  Small Devices (landscape phones, 576px and up)  */

@media (min-width: 320px) and (max-width:767px) {

  /* ------------------------ Home Page Section ------------------------ */

  .content {
    width: 100%;
    height: 200px;
    margin-top: 45px !important;
    padding: 50px;
  }

  .LandingPageSubTitle {
    width: 300px;
    font-size: 0.7rem;
  }

  .overlay {
    height: 200px;

  }

  .Navbar-logo {
    width: 100px;
  }

  .DownArrowLandingPage {
    display: none;
  }

  Video {
    max-height: 700px;
  }

  /* ------------------------ LeadsGenerated Section ------------------------ */

  .LeadGeneratedTitle {
    width: 100%;
    padding: 0px;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .LeadsGeneratedSection {
    padding: 15px;
  }


  .LeadsGeneratedTopCont {
    flex-direction: column;
    align-items: center;
  }

  .LogoLetters {
    margin-top: 20px;
  }



  /* ------------------------ First Call To action ------------------------ */

  .CallToActionContainer {
    padding: 25px;
  }

  .CallToActionInformation {
    width: 80%;

  }

  .CallActionimg {
    width: 300px;
  }

  /* ------------------------ About Us Section ------------------------ */

  .PrincipalTitleAboutUs {
    flex-direction: column;
  }

  .AboutUsInfoContainer {
    padding: 25px;
  }

  .AboutUsImg {
    max-width: 400px;
    width: 300px;
  }

  /* ------------------------ How Section ------------------------ */

  .HowSectionContainerDescription {
    padding: 50px;
  }

  .HowSectionContainer {
    margin: 0 auto;

  }

  .HowInfoContainer {
    min-width: 320px;
    margin-top: 30px;
  }

  .ImageHowTwo {
    width: 300px !important;
  }

  /* ------------------------ Team Section ------------------------ */

  .BannerTeamTitle {
    width: 100%;
  }


  .AvatarCard {
    margin-top: 10px;
  }

  .PrincipalTitleTeamSection {
    width: 50%;
  }

  .TeamSubtitle {
    width: 50%;
  }

  .TeamSectionContainer {
    padding: 20px;
  }

  /* ------------------------ Pricing Section ------------------------ */



  .PricingSectionTitleBold {
    text-align: center;
    padding: 10px;
  }

  .ServicesCardPricing {
    margin-top: 10px;
  }

  .CustomPlanContainer {
    flex-direction: column;
    align-items: center;
    max-height: 700px;

  }

  .CustomPlanInfoContainer {
    width: 100%;
  }


  /* ------------------------ Quote Know ------------------------ */


  .QuoteNowSectionContainer {

    flex-direction: row;
    padding: 0px !important;
    max-width: 300px;
    border: none;
  }


  .QuoteNowFullContainer {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    min-width: 300px;
  }

  .QuoteEmailInput {
    max-width: 250px;
  }

  .TextTareaInput {
    max-width: 290px;
  }

  .QuoteSendBtn {
    max-width: 290px;

  }

  /* ------------------------ Google Partner ------------------------ */

  .PartnerBGoogleContainer {
    padding: 25px;
  }

  .PartnerBGoogleContainer {
    flex-direction: column;
    align-content: center;
    justify-content: center;

  }

  .PartnerBGoogleInfoCont {
    flex-direction: column;
    max-width: 280px;
    padding: 0px;
  }

  .PartnerBGoogleInfoCont {
    max-width: 300px;
  }

  .DescriptionBGoogle {
    min-width: 300px;
  }



  /* ------------------------ Footer Section ------------------------ */

  .FooterColumnsContainer {
    flex-wrap: wrap;
  }

  .FooterColumn {
    min-width: 200px;
    margin-top: 20px;
  }

  /* ------------------------ Blog Section ------------------------ */

  .LandingPageTitle{
    font-size: 1.3rem !important;
  }

  .LandingPageBtn{
    display: none;
  }
}

/*  Medium Devices (tablets, 768px up)  */


@media (min-width: 768px) and (max-width:991px) {

  /* ------------------------ Landing Page Section ------------------------ */


  .content {
    height: 300px;
    margin-top: 90px !important;
  }

  .overlay {
    height: 428px;
  }

  .PrincipalLogoHome {
    width: 150px !important;
  }

  /* ------------------------ LeadsGenerated Section ------------------------ */

  .LeadsGeneratedTopCont {
    max-width: 500px;
    margin: 0 auto;
  }

  /* ------------------------ Call To action ------------------------ */

  .CallToActionContainer {
    padding: 25px;
  }

  .CallToActionInformation {
    width: 80%;

  }

  /* ------------------------ About Us Section ------------------------ */



  .AboutUsImg {
    max-width: 500px;
  }


  /* ------------------------ Pricing Section ------------------------ */
  
  .PricingSectionTitleBold{
    width: 100%;
    padding: 20px;
  }
  .ServicesCardPricing{
    margin-top: 20px;
  }

  /* ------------------------ Contact Section ------------------------ */



  .PricingSectionTitleBold {
    text-align: center;
  }

  /* ------------------------ Quote Section  ------------------------ */

  .QuoteNowSectionContainer{
    border: 1px solid black;

  }

  .QuoteNowFullContainer{
    border: 1px solid white;
    border-radius: 20px;
    padding: 0px;
    margin: 0px !important;
  }

  .QuoteFormContainer{
    width: 400px;
    margin-right: 10px;
  }

  .TextTareaInput{
    width: 380px;
  }

  .QuoteSendBtn{
    width: 380px;
    margin-bottom: 20px;

  }

  /* ------------------------ Partner Banner ------------------------ */
  
  .PartnerBGoogleInfoCont{
    justify-content: center;
    align-content: center;
    align-items: center;
  }
  .PartnerBGoogleInfoCont img{

    width: 300px;
    height: 200px;

  }



  /* ------------------------ Footer Section ------------------------ */

  .FooterSectionContainer{
    padding: 0px;
  }

  .FooterColumnsContainer {
    flex-wrap: wrap;
  }

  .FooterColumnsContainer {
    border: 1px solid purple;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
  }

  .FooterColumn {
    min-width: 100px;
    margin-top: 20px;
    margin-left: 40px;
    margin-right: 40px;
  }

  .FooterColumnLogo {
    justify-content: center;
    align-content: center;
    display: flex;
    align-items: center;
    margin-right: 20px;
  }

}


/*  Large Devices (desktops, 992px up)  */


@media (min-width: 992px) and (max-width: 1200px) {

  /* ----------------------------  LandingPage Home ----------------------------  */

  .content {
    height: 300px;
    margin-top: 200px !important;
  }

  .overlay {
    height: 580px;
  }

  .PrincipalLogoHome {
    width: 200px !important;
  }

  /* ---------------------------- Pricing Section ----------------------------  */


  .PricingSectionTitleBold {
    width: 80%;
  }

  /* ----------------------------  Quote Section Home ----------------------------  */

  .QuoteNowSectionContainer {
    width: 80%;
  }
}

/*  X-Large Devices (desktops, 1200px up)  */


@media (min-width: 1200px) {}



/*  X-Large Devices (desktops, 1200px up)  */


@media (min-width: 1400px) {}