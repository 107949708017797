:root {
    --orange-bg-color: #F47320;
    --black-bg-color: #000;


}

.NavBarContainer {
    display: flex;
    justify-content: space-around !important;
    background-color: transparent;
    margin-top: 60px;
    z-index: 9999 !important;
    transition: 0.9s;
    position: fixed;



}


.NavBarActivate {
    margin-top: 0px;
    background-color: var(--black-bg-color) !important;
}

.LenguageButton {
    background-color: var(--orange-bg-color);
    color: white;
    border: none;
    padding: 3px 5px 3px 5px;
    border-radius: 3px;
    cursor: pointer;
    margin-left: 5px;
    margin-right: 5px;
}

.NavLinkNavBar {
    margin-left: 10px;
    margin-right: 10px;
    font-size: 1.1rem;
    font-weight: bold;
}

.Overlay {
    z-index: 200;
    margin-top: 55px;
    background-color: black;
    position: fixed;
    opacity: 1;
}


.NavLinkNavBar {
    color: white;
}

.NavBarItemsFullContainer {
    display: flex;
    max-width: 1500px;
    width: 96%;
    align-items: center;
    justify-content: space-between;
}


.MobileLogo{
    width: 100px;
}


.LenguageBtnContainer {
    display: flex;
}

.Nav-Link {
    display: flex;
    align-items: center;
    font-size: 1rem !important;
    justify-content: center;
    cursor: pointer;
    margin-left: 20px !important;
    color: white;
    margin-right: 20px !important;
}

.NavLinkQuote {
    background-color: #000;
    border-radius: 30px;
    height: 30px;
    width: 90px;
    transition: 1s;
    font-size: 0.8rem !important;
}

.NavLinkQuote:hover{
    background-color: white;
    color: black;
}

.NavLinkQuoteActivate{
    background-color: #fff;
    border-radius: 30px;
    height: 30px;
    width: 90px;
    color: black;
    transition: 1s;
    font-size: 0.8rem !important;
}

.NavLinkQuoteActivate:hover{
  background-color: black;
  border-radius: 1px solid white;
  color: white;
}




.Overlay {
    margin-top: 0px;
    z-index: 1111 !important;

}

/* Media Responsive */
@media screen and (max-width: 850px) {


    .Overlay {
        margin-top: 0px;
        z-index: 1111 !important;

    }

    .Nav-Logo {
        width: 280px;
        margin-left: 0px;
        text-align: center;
        margin-top: 25px;
    }

    .NavBarContainer {
        margin-top: 0px;
    }

    .Btn-home-container {
        display: none !important;
    }

    .Info-container-home {
        margin-top: 50px !important;
    }

    .sc-bdvvtL {
        padding: 0px !important;
        display: flex;
        width: 100vw !important;
    }

    .Nav-Link {
        margin: 0 auto !important;
    }
}


/* ID NAVBAR */


#NavLinksContainer {
    padding: 0px !important;
    margin: 0px !important;
    display: flex;
}



.TabActive {
    color: var(--orange-bg-color) !important;
}


.OverlayMenu {
    z-index: -9;
}